import { defineAsyncComponent } from 'vue';
import type { CdekComponentNames } from '@/types/dto/cdekComponent';

const Alerts = defineAsyncComponent({
  loader: () => import('@/admin-components/Alerts/Alerts.vue'),
  delay: 0,
});

const Digits = defineAsyncComponent({
  loader: () => import('./Digits/Digits.vue'),
  delay: 0,
});

const BannerStatic = defineAsyncComponent({
  loader: () => import('./Banner/BannerStatic.vue'),
  delay: 0,
});

const OfficeMap = defineAsyncComponent({
  loader: () => import('./Offices/OfficeMap.vue'),
  delay: 0,
});

const TrackingV2 = defineAsyncComponent({
  loader: () => import('./TrackingNew/Tracking.vue'),
  delay: 0,
});
const ComponentNotFound = defineAsyncComponent({
  loader: () => import('@/admin-components/ComponentNotFound.vue'),
  delay: 0,
});
const MobileApplications = defineAsyncComponent({
  loader: () => import('./MobileApplications/MobileApplications.vue'),
  delay: 0,
});
const Questions = defineAsyncComponent({
  loader: () => import('@/components/Questions/Questions.vue'),
  delay: 0,
});
const ClientQuestions = defineAsyncComponent({
  loader: () => import('./ClientQuestion/ClientQuestions.vue'),
  delay: 0,
});
const UnmarkedList = defineAsyncComponent({
  loader: () => import('./UnmarkedList/UnmarkedList.vue'),
  delay: 0,
});

const Tiles = defineAsyncComponent({
  loader: () => import('@/admin-components/Tiles/Tiles.vue'),
  delay: 0,
});

const Documents = defineAsyncComponent({
  loader: () => import('./Documents/Documents.vue'),
  delay: 0,
});

const NewBlock = defineAsyncComponent({
  loader: () => import('./NewBlock/NewBlock.vue'),
  delay: 0,
});

const Tariffs = defineAsyncComponent({
  loader: () => import('./Tariffs.vue'),
  delay: 0,
});

const BannerMobile = defineAsyncComponent({
  loader: () => import('./BannerMobile.vue'),
  delay: 0,
});

const BannerCarousel = defineAsyncComponent({
  loader: () => import('./BannerCarousel/BannerCarousel.vue'),
  delay: 0,
});

const BaseLoader = defineAsyncComponent({
  loader: () => import('./BaseLoader.vue'),
  delay: 0,
});

const BanerShoppingTop = defineAsyncComponent({
  loader: () => import('@/admin-components/Shopping/CdekShopping.vue'),
  delay: 0,
});
const Banner = defineAsyncComponent({
  loader: () => import('./Banner/Banner.vue'),
  delay: 0,
});
const Cover = defineAsyncComponent({
  loader: () => import('./Cover/Cover.vue'),
  delay: 0,
});
const TextBlock = defineAsyncComponent({
  loader: () => import('./TextBlock/TextBlock.vue'),
  delay: 0,
});

const BaseInput = defineAsyncComponent({
  loader: () => import('./BaseComponents/BaseInput.vue'),
  delay: 0,
});

const PressNewList = defineAsyncComponent({
  loader: () => import('./PressNewList/PressNewList.vue'),
  delay: 0,
});

const Intro = defineAsyncComponent({
  loader: () => import('./Intro.vue'),
  delay: 0,
});

const CoverNew = defineAsyncComponent({
  loader: () => import('./Cover/CoverNew.vue'),
  delay: 0,
});

const CdekServices = defineAsyncComponent({
  loader: () => import('@/admin-components/CdekServices/CdekServices.vue'),
  delay: 0,
});

const FeedbackBlock = defineAsyncComponent({
  loader: () => import('./FeedbackBlock/FeedbackBlock.vue'),
  delay: 0,
});
const Unsubscribe = defineAsyncComponent({
  loader: () => import('./Unsubscribe/Unsubscribe.vue'),
  delay: 0,
});

const Tabs = defineAsyncComponent({
  loader: () => import('./Tabs/Tabs.vue'),
  delay: 0,
});

const OfficeHeader = defineAsyncComponent({
  loader: () => import('./Offices/OfficeHeader.vue'),
  delay: 0,
});

const Payment = defineAsyncComponent({
  loader: () => import('./Payment/PaymentV2/PaymentV2.vue'),
  delay: 0,
});
const Top = defineAsyncComponent({
  loader: () => import('./Top/Top.vue'),
  delay: 0,
});

const PackageHeader = defineAsyncComponent({
  loader: () => import('./Package/PackageHeader.vue'),
  delay: 0,
});

const PackageBoxCard = defineAsyncComponent({
  loader: () => import('./Package/PackageBoxCard.vue'),
  delay: 0,
});

const PackageCard = defineAsyncComponent({
  loader: () => import('./Package/PackageCard.vue'),
  delay: 0,
});

const NewList = defineAsyncComponent({
  loader: () => import('./NewList/NewList.vue'),
  delay: 0,
});

const NewsVideoList = defineAsyncComponent({
  loader: () => import('./NewList/NewsVideoList.vue'),
  delay: 0,
});

const Email = defineAsyncComponent({
  loader: () => import('./Email/Email.vue'),
  delay: 0,
});

const Office = defineAsyncComponent({
  loader: () => import('./Offices/Office.vue'),
  delay: 0,
});

const SingleButtonBlock = defineAsyncComponent({
  loader: () => import('./SingleButtonBlock.vue'),
  delay: 0,
});

const Contacts = defineAsyncComponent({
  loader: () => import('./Contacts/Contacts.vue'),
  delay: 0,
});

const Partners = defineAsyncComponent({
  loader: () => import('./Partners.vue'),
  delay: 0,
});

const NewItem = defineAsyncComponent({
  loader: () => import('./SingleNews/SingleNews.vue'),
  delay: 0,
});

const History = defineAsyncComponent({
  loader: () => import('./History/History.vue'),
  delay: 0,
});

const FeedbackList = defineAsyncComponent({
  loader: () => import('./FeedbackList/FeedbackList.vue'),
  delay: 0,
});

const NumberBlocks = defineAsyncComponent({
  loader: () => import('./NumberBlocks/NumberBlocks.vue'),
  delay: 0,
});

const TrackingQuestions = defineAsyncComponent({
  loader: () => import('./TrackingNew/TrackingDocuments.vue'),
  delay: 0,
});
const Dogovor = defineAsyncComponent({
  loader: () => import('./Dogovor/Dogovor.vue'),
  delay: 0,
});
const DogovorKZ = defineAsyncComponent({
  loader: () => import('./DogovorKZ/Dogovor.vue'),
  delay: 0,
});
const DogovorUZ = defineAsyncComponent({
  loader: () => import('./DogovorUZ/Dogovor.vue'),
  delay: 0,
});
const DogovorKG = defineAsyncComponent({
  loader: () => import('./DogovorKG/Dogovor.vue'),
  delay: 0,
});
const DogovorAM = defineAsyncComponent({
  loader: () => import('./DogovorAM/Dogovor.vue'),
  delay: 0,
});
const CalculatorNew = defineAsyncComponent({
  loader: () => import('./NewOrder/CalculatorNew.vue'),
  delay: 0,
});
const TrackingInfo = defineAsyncComponent({
  loader: () => import('./TrackingInfo/TrackingInfo.vue'),
  delay: 0,
});

const TrackingApplications = defineAsyncComponent({
  loader: () => import('./TrackingNew/TrackingApplications.vue'),
  delay: 0,
});

const TwoColumns = defineAsyncComponent({
  loader: () => import('@/admin-components/TwoColumns/TwoColumns.vue'),
  delay: 0,
});

const LandingCover = defineAsyncComponent({
  loader: () => import('./LandingCover.vue'),
  delay: 0,
});

const PopularProducts = defineAsyncComponent({
  loader: () => import('@/admin-components/PopularProducts/PopularProducts.vue'),
  delay: 0,
});

const ActionsCarousel = defineAsyncComponent({
  loader: () => import('@/admin-components/ActionsCarousel/ActionsCarousel.vue'),
  delay: 0,
});

const LinkBlock = defineAsyncComponent({
  loader: () => import('@/admin-components/LinkBlock/LinkBlock.vue'),
  delay: 0,
});

// Компонент удалю отдлельной задачей после успешного релиза
// Это самый первый компонент на главной, пусть лучше пока побудет, чем пользователи
// вдруг увидят "компонент не найден"
const Banners = defineAsyncComponent({
  loader: () => import('@/admin-components/Banners/Banners.vue'),
  delay: 0,
});

const CoverTabs = defineAsyncComponent({
  loader: () => import('./Cover/CoverTabs.vue'),
  delay: 0,
});

const LandingIllustration = defineAsyncComponent({
  loader: () => import('./LandingIllustration/LandingIllustration.vue'),
  delay: 0,
});

const Messengers = defineAsyncComponent({
  loader: () => import('./Messengers.vue'),
  delay: 0,
});

const PackageForm = defineAsyncComponent({
  loader: () => import('./Package/PackageForm.vue'),
  delay: 0,
});

const SocialLinks = defineAsyncComponent({
  loader: () => import('./SocialLinks/SocialLinks.vue'),
  delay: 0,
});

const TableWithTitle = defineAsyncComponent({
  loader: () => import('./TableWithTitle/TableWithTitle.vue'),
  delay: 0,
});

const TextBlockLogo = defineAsyncComponent({
  loader: () => import('./TextBlock/TextBlockLogo.vue'),
  delay: 0,
});

const YouTubeSlider = defineAsyncComponent({
  loader: () => import('./YouTubeSlider/YouTubeSlider.vue'),
  delay: 0,
});

const Universal = defineAsyncComponent({
  loader: () => import('@/admin-components/UniversalComponent.vue'),
  delay: 0,
});

const PictureGallery = defineAsyncComponent({
  loader: () => import('./PictureGallery/PictureGallery.vue'),
  delay: 0,
});

const FaqHtmlBlock = defineAsyncComponent({
  loader: () => import('@/components/FaqHtmlBlock/FaqHtmlBlock.vue'),
  delay: 0,
});

const globalComponents: Record<CdekComponentNames, any> = {
  FaqHtmlBlock,
  PictureGallery,
  Universal,
  LandingCover,
  Unsubscribe,
  TwoColumns,
  TrackingApplications,
  TrackingInfo,
  CalculatorNew,
  Dogovor,
  DogovorKZ,
  DogovorUZ,
  DogovorKG,
  DogovorAM,
  Office,
  OfficeHeader,
  CdekServices,
  PressNewList,
  Tabs,
  Email,
  NewsTabs: Tabs,
  TrackingV2,
  Alerts,
  NewBlock,
  ComponentNotFound,
  OfficeMap,
  CoverNew,
  Tiles,
  BannerCarousel,
  BannerStatic,
  MobileApplications,
  Questions,
  BanerShoppingTop,
  BaseLoader,
  Tariffs,
  BaseInput,
  UnmarkedList,
  Digits,
  FeedbackBlock,
  Intro,
  ClientQuestions,
  Payment,
  Banner,
  TextBlock,
  Documents,
  Top,
  PackageHeader,
  PackageBoxCard,
  PackageCard,
  NewList,
  Videos: NewsVideoList,
  BannerMobile,
  Cover,
  SingleButtonBlock,
  Contacts,
  Partners,
  NewItem,
  PressNewItem: NewItem,
  History,
  FeedbackList,
  NumberBlocks,
  TrackingQuestions,
  ActionsCarousel,
  PopularProducts,
  LinkBlock,
  Banners,
  CoverTabs,
  LandingIllustration,
  Messengers,
  PackageForm,
  SocialLinks,
  TableWithTitle,
  TextBlockLogo,
  YouTubeSlider,
};

export default globalComponents;
